// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://76c11b094776943f873280b496a37139@o379043.ingest.sentry.io/4505938675171328',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.SENTRY_ENV,
  ignoreErrors: [
    // These errors are caused by the user cancelling the request.
    // Reference: https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
    // either when they click the "X" button or when they navigate away from the page too quickly.
    // unfortunately with fetch API, we don't have a clean way to detect whether an error
    // is due to the request being cancelled or not.
    // So I have checked this https://request-cancellation-test.vercel.app/ for the error message
    // that is thrown when the request is cancelled and added it to the ignoreErrors list.
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Load failed',
    'TypeError: cancelled',
  ],
});
