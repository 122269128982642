import '../styles/main.scss';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
// import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';

const GTM_ID = process.env.GTM_ID;

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Initialize Google Tag Manager
    if (GTM_ID) {
      TagManager.initialize({ gtmId: GTM_ID });
    }
  }, []);

  // // Logs new events from the LiveChat widget, useful for debugging
  // function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
  //   console.log('LiveChatWidget.onNewEvent', event);
  // }

  return (
    <>
      {/* <LiveChatWidget
        license="7376891"
        visibility="maximized"
        onNewEvent={handleNewEvent}
      /> */}
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
